































import {
  Vue, Component, Prop, PropSync,
} from 'vue-property-decorator';
import { Resource } from '@api-platform/api-doc-parser/lib/Resource';
import { Field } from '@api-platform/api-doc-parser/lib/Field';
import { BvTableField, BvTableFieldArray } from 'bootstrap-vue';

@Component
export default class PropertySelectorPopover extends Vue {
  @Prop({ type: Object, required: true }) resource!: Resource

  @Prop({ type: Array, required: true }) sortableProperties!: string[];

  @PropSync('selectedProperties', { type: Array, required: true })
    syncedSelectedProperties!: BvTableFieldArray;

  get availableProperties() {
    const tableFields:
      { text: string; value: { key: string } & BvTableField }[] = [];
    if (this.resource?.fields) {
      (this.resource.readableFields as Field[]).forEach((f) => {
        tableFields.push({
          text: f.name,
          value: {
            key: f.name,
            label: f.name.charAt(0).toUpperCase() + f.name.slice(1),
            sortable: this.sortableProperties.includes(f.name),
          },
        });
      });
    }

    return tableFields;
  }
}
