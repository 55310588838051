import { Vue, Component } from 'vue-property-decorator';

@Component
export default class FormatLabelMixin extends Vue {
  /**
   * Formats the label of the filter inputs.
   * This includes:
   *   - Removing "translations."
   *   - Capitalizing the first letter
   *   - Replacing brackets with braces
   *   - Replacing underscores with whitespace
   *
   * @param {string} text
   */
  formatLabel(text: string) {
    const trimmed = text.replace('translations.', '');
    const capitalized = trimmed.charAt(0).toUpperCase() + trimmed.slice(1);
    const braces = capitalized.replace('[', ' (').replace(']', ')');
    return braces.replace('_', ' ');
  }
}
