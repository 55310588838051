/* eslint-disable import/prefer-default-export */
/**
 * This function removed the API prefix from an resources URL. This is needed
 * to generate fitting frontend urls.
 *
 * Example: GET route for users: /api/users
 * Function returns just /users.
 *
 * @param url
 */
export function removeApiPrefix(url: string): string {
  const regexString = `${process.env.VUE_APP_API_PREFIX}(.*)`;
  const regex = new RegExp(regexString);
  const matches = url.match(regex) ?? [];
  if (matches.length > 0) {
    return matches[1];
  }

  return '';
}

export function getContext(context: string): string {
  return context.split('/').reverse()[0];
}
