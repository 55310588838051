






























































import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import { Field } from '@api-platform/api-doc-parser/lib/Field';
import { BTable, BvTableCtxObject, BvTableFieldArray } from 'bootstrap-vue';
import { Resource } from '@api-platform/api-doc-parser/lib/Resource';
import { BvTableField } from 'bootstrap-vue/src/components/table';
import FilterCard from '@/components/List/FilterCard.vue';
import PropertySelectorPopover from '@/components/List/PropertySelectorPopover.vue';
import FieldToStringGuesser from '@/components/List/FieldToStringGuesser.vue';
import ContextMenu from '@/components/Layout/ContextMenu.vue';
import { ApiResource } from '@/types/resources/ApiResource';
import { FilterMapping } from '@/types/shared/Filtering';
import { ContextMenuOption } from '@/types/interfaces/ContextMenu/ContextMenuOptionInterface';
import { ContextMenuInterface } from '@/types/interfaces/ContextMenu/ContextMenuInterface';

@Component({
  components: {
    ContextMenu,
    PropertySelectorPopover,
    FilterCard,
    FieldToStringGuesser,
  },
})
export default class ListTable extends Vue {
  @Prop({ type: Object, required: true }) resource!: Resource;

  public $refs!: {
    listTable: BTable;
    contextMenu: ContextMenu & ContextMenuInterface;
  };

  /** All available filters. Will be filled in resourceProvider */
  private filters: FilterMapping[] = [];

  /** Names or all sortable properties. Will be filled in resourceProvider */
  private sortableProperties: string[] = [];

  /** Fields to show in table. Will be filled in resourceProvider once */
  private fields: BvTableFieldArray = [];

  private totalItems = 0;

  private currentPage = 1;

  get contextOptions(): ContextMenuOption[] {
    const options = [
      { name: 'View', faIcon: ['fad', 'eye'] },
      { name: 'Edit', faIcon: ['fad', 'pencil'] },
      { name: 'Delete', faIcon: ['fad', 'trash'] },
    ];

    // For some reason, TypeScript complains about this if condition, but it is wrong
    // for sure.
    // eslint-disable-next-line
    // @ts-ignore
    if (this.resource.title !== 'Template' || this.resource.title !== 'Content') {
      options.splice(2, 0, { name: 'Duplicate', faIcon: ['fal', 'plus'] });
    }

    return options;
  }

  /**
   * @return {boolean} - True if any filters are set
   */
  get areFiltersSet() {
    let onlyEmpty = false;
    this.filters.forEach((f) => {
      if (f.value) {
        onlyEmpty = true;
      }
    });

    return onlyEmpty;
  }

  navigateToResource(iri: string) {
    this.$router.push({
      name: 'view-resource',
      params: {
        resourceTitle: this.$route.params.resourceTitle,
        resourceId: iri.split('/').reverse()[0],
      },
    });
  }

  openContextMenu(item: ApiResource, index: number, event: MouseEvent) {
    this.$refs.contextMenu.openMenu(item, event);
  }

  async contextOptionClicked(item: ApiResource, option: ContextMenuOption) {
    let routeName = '';
    const query: { [key: string]: string} = {};

    switch (option.name) {
      case 'View':
        routeName = 'view-resource';
        break;
      case 'Edit':
        routeName = 'edit-resource';
        break;
      case 'Delete':
        await this.deleteResource(item);
        break;
      case 'Duplicate':
        routeName = 'edit-resource';
        query.duplicate = 'true';
        break;
      default:
    }

    if (routeName !== '') {
      this.$router.push({
        name: routeName,
        params: {
          resourceTitle: this.$route.params.resourceTitle,
          resourceId: item['@id'].split('/').reverse()[0],
        },
        query,
      });
    }
  }

  async deleteResource(item: ApiResource) {
    await this.$api.delete(item['@id']);
    this.$refs.contextMenu.closeMenu();
    this.$refs.listTable.refresh();
  }

  /**
   * Provider function for Bootstrap table.
   * Gets filtered and ordered resources from backend, fills this.filters with
   * all available filters and fills this.fields if it is not filled yet.
   * @param ctx
   */
  async resourceProvider(ctx: BvTableCtxObject) {
    const params: { [key: string]: string | number } = {
      locale: this.$root.$i18n.locale,
      page: ctx.currentPage,
    };

    if (ctx.sortBy) {
      params[`order[${ctx.sortBy}]`] = ctx.sortDesc ? 'DESC' : 'ASC';
    }

    this.filters.forEach((f) => {
      if (f.value) {
        params[f.variable] = f.value;
      }
    });

    const response = await this.$api.get(this.resource.url, {
      params,
    });
    if (response.status === 200) {
      if (response.data['hydra:totalItems']) {
        this.totalItems = response.data['hydra:totalItems'];
      }
      if (response.data['hydra:member']) {
        // Fill in available filters
        if (this.filters.length === 0 && response.data['hydra:search']['hydra:mapping']) {
          const filterMapping: FilterMapping[] = response.data['hydra:search']['hydra:mapping'];
          filterMapping.forEach((m) => {
            this.filters.push(m);

            if (m.variable.includes('order')) {
              const orderProperty = m.variable.match(/order\[(\w+)\]/);
              if (orderProperty && orderProperty.length > 1) {
                this.sortableProperties.push(orderProperty[1]);
              }
            }
          });

          // Pre-fill fields/properties for table, but only if it has not yet been set
          if (this.fields.length === 0 && this.resource?.fields) {
            this.fields.push({ key: '@id', label: 'Id', sortable: false });
            (this.resource.readableFields as Field[]).forEach((f) => {
              const fieldToAdd = {
                key: f.name,
                label: f.name.charAt(0).toUpperCase() + f.name.slice(1),
                sortable: this.sortableProperties.includes(f.name),
              };

              if (fieldToAdd.key === 'name') { // Make sure name is added directly after id
                this.fields.splice(1, 0, fieldToAdd);
              } else { // All other fields are pushed to the end
                this.fields.push(fieldToAdd);
              }
            });
          }
        }
        return response.data['hydra:member'];
      }
    }
    return [];
  }

  /**
   * Refresh table in case filters changed.
   */
  filter() {
    this.$refs.listTable.refresh();
  }

  /**
   * Iterate over available filters, setting their value to an empty string.
   */
  resetFilters() {
    this.filters.forEach((f) => {
      // eslint-disable-next-line no-param-reassign
      f.value = '';
    });
    this.$refs.listTable.refresh();
  }

  /**
   * Resets a single filter's value.
   */
  resetFilter(filterName: string) {
    const filter = this.filters.find((f) => f.variable === filterName);
    if (filter) {
      filter.value = null;
      this.$refs.listTable.refresh();
    }
  }

  /**
   * Populate this.fields array. Use values stored in Vuex, if available.
   */
  populateFields() {
    this.fields = [];
    const shownPropertiesFromState = this.$store.getters['listView/showPropertiesForResource'](this.resource.name);
    if (shownPropertiesFromState) {
      this.fields = shownPropertiesFromState;
    }
  }

  /**
   * If resource changed (from Sidenav), reset filters, populate this.fields
   * and refresh the table with the new resource.
   */
  @Watch('resource')
  changeOfResource() {
    this.filters = [];
    this.populateFields();
    this.$refs.listTable.refresh();
  }

  /** If this.fields change (eg. from PropertySelectorPopover), store the selected
   * fields in Vuex.
   * @param value
   */
  @Watch('fields')
  storeShownProperties(value: { key: string } & BvTableField) {
    this.$store.commit('listView/SET_SHOWN_PROPERTIES', {
      resource: this.resource.name,
      shownProperties: value,
    });
  }

  @Watch('$root.$i18n.locale')
  reloadList() {
    this.$refs.listTable.refresh();
  }
}
