






import { Vue, Component } from 'vue-property-decorator';
import { Resource } from '@api-platform/api-doc-parser/lib/Resource';
import ListTable from '@/components/List/ListTable.vue';

@Component({
  components: { ListTable },
})
export default class ListResource extends Vue {
  get resource() {
    const param = this.$route.params.resourceTitle;
    return this.$store.state.api.resources.find((r: Resource) => r.url.endsWith(`/${param}`));
  }
}
